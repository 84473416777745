import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Section from "../components/section"
import ParallaxCard from "../components/parallax-card"
import Divider from "../components/divider"
import Products from "../components/products"

const IndexPage = () => {
  return (
    <Layout>
      <Seo title="Thierry Schmidt" />
      <Section backgroundTheme="dark">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 py-16 md:h-screen">
          <div className="flex flex-col justify-center">
            <h1 className="text-base font-semibold text-pink-600 m-0">Thierry Schmidt</h1>
            <h2 className="text-5xl m-0">
              <div>Webentwickler</div>
              <div className="md:ml-10">mit Leidenschaft</div>
            </h2>
          </div>
          <ParallaxCard />
        </div>
      </Section>
      <Divider position="end" />
      <Section className="my-24">
        <h2 className="text-3xl text-gray-900">Meine Arbeit</h2>
        <Products />
      </Section>
    </Layout>
  )
}

export default IndexPage
