import * as React from 'react';

const Footer = ({ className }) => (
    <footer className={`${className ? className : ''} m-0 py-6 text-center`}>
        <p className="p-4">
            by Thierry Schmidt
        </p>
    </footer>
);

export default Footer;
