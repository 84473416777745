import * as React from 'react';
import { Helmet } from 'react-helmet';
import "@fontsource/poppins"; // Defaults to weight 400.
import "@fontsource/poppins/600.css";
import Footer from './footer';
import Section from './section';
import Divider from './divider';

const Layout = ({ children }) => (
    <>
        <Helmet
            htmlAttributes={{
                lang: 'de',
            }}
        >
        </Helmet>
        <div>
            <main className="font-sans" style={{ flex: '1 0 auto' }}>
                {children}
            </main>
            <Divider position="start" />
            <Section backgroundTheme="dark" className="-mt-px">
                <Footer />
            </Section>
        </div>
    </>
);

export default Layout;
