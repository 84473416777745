import React from 'react';
import { useSpring, animated } from '@react-spring/web';

import "./parallax-card.css";
import Card from './card';

const calc = (x, y) => [-(y - window.innerHeight * 0.5) / 100, ((x) - window.innerWidth * 0.75) / 20, 1.1]
const trans = (x, y, s) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`

const ParallaxCard = () => {
    const [props, set] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 5, tension: 280, friction: 30 } }));

    return (
        <>
            <animated.div
                className="hidden md:block parallax-card mx-auto p-6 relative"
                onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
                onMouseLeave={() => set({ xys: [0, 0, 1] })}
                style={{ transform: props.xys.to(trans) }}
            >
                <Card />
            </animated.div>
            <div className="md:hidden mt-16 parallax-card mx-auto p-6 relative">
                <Card />
            </div>
        </>
    );
}

export default ParallaxCard;