import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

const tech = ['JavaScript', 'React', 'Gatsby', 'Tailwind CSS'];
const soz = ['Squarespace'];
const reg = ['JavaScript', 'React', 'Gatsby', 'CSS'];

const Products = () => (
    <div className="mt-12 grid md:grid-cols-3 gap-10">
        <div className="group relative cursor-pointer shadow-md">
            <div>
                <StaticImage
                    src="../images/yas-teaser.png"
                    alt="Porftolio Webseite von Yasmin Abdullahi"
                    className="rounded-t"
                />
            </div>
            <div className="text-gray-900 px-4 pt-8 pb-4 rounded border border-t-0">
                <p className="font-semibold">Webseite</p>
                <p>
                    Portfolio der Stadträtin Yasmin Abdullahi
                </p>
                <div className="flex gap-2 pt-8 flex-wrap">
                    {tech.map(item => (
                        <span
                            key={item}
                            className="text-sm px-2 py-px rounded-full border border-gray-300 bg-gray-100"
                        >
                            {item}
                        </span>
                    ))}
                </div>
            </div>
            <a
                href="https://www.yasminabdullahi.ch"
                target="_blank"
                rel="noreferrer"
                className="opacity-80 md:opacity-0 group-hover:opacity-80 rounded absolute inset-0 bg-gray-800 flex justify-center items-center text-pink-600"
            >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-24 w-24" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                </svg>
            </a>
        </div>
        <div className="group relative cursor-pointer shadow-md">
            <div>
                <StaticImage
                    src="../images/dieter-teaser.png"
                    alt="Webseite für die Weiterbildung in der Sozialversichrung"
                    className="rounded-t"
                />
            </div>
            <div className="text-gray-900 px-4 pt-8 pb-4 rounded border border-t-0">
                <p className="font-semibold">Webseite</p>
                <p>
                    Weiterbildung in der Sozialversicherung
                </p>
                <div className="flex gap-2 pt-8 flex-wrap">
                    {soz.map(item => (
                        <span
                            key={item}
                            className="text-sm px-2 py-px rounded-full border border-gray-300 bg-gray-100"
                        >
                            {item}
                        </span>
                    ))}
                </div>
            </div>
            <a
                href="https://www.dieter-widmer.ch"
                target="_blank"
                rel="noreferrer"
                className="opacity-80 md:opacity-0 group-hover:opacity-80 rounded absolute inset-0 bg-gray-800 flex justify-center items-center text-pink-600"
            >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-24 w-24" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                </svg>
            </a>
        </div>
        <div className="group relative cursor-pointer shadow-md">
            <div>
                <StaticImage
                    src="../images/regkast.png"
                    alt="Engagierte Sozialarbeiterin in der Sozialhilfe und Sozialberatung."
                    className="rounded-t"
                />
            </div>
            <div className="text-gray-900 px-4 pt-8 pb-4 rounded border border-t-0">
                <p className="font-semibold">Webseite</p>
                <p>
                    Regula Kast Sozialberatung
                </p>
                <div className="flex gap-2 pt-8 flex-wrap">
                    {reg.map(item => (
                        <span
                            key={item}
                            className="text-sm px-2 py-px rounded-full border border-gray-300 bg-gray-100"
                        >
                            {item}
                        </span>
                    ))}
                </div>
            </div>
            <a
                href="https://regulakast-sozialberatung.ch/"
                target="_blank"
                rel="noreferrer"
                className="opacity-80 md:opacity-0 group-hover:opacity-80 rounded absolute inset-0 bg-gray-800 flex justify-center items-center text-pink-600"
            >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-24 w-24" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                </svg>
            </a>
        </div>
    </div>
);

export default Products;