import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image'

const at = '@'
const client = 'gmail.com';
const mail = 'schmidt.tps';

const Card = () => (
    <>
        <div className="flex justify-center">
            <StaticImage
                alt="Profil"
                src="../images/pb.jpeg"
                width={140}
                aspectRatio={1}
                className="rounded-full"
            />
        </div>
        <div className="mt-12 px-2">
            <p>Thierry Schmidt</p>
            <p>BSc Medizininformatik</p>
            <a className="text-pink-600 font-semibold" href={`mailto:${mail}${at}${client}`}>
                {`${mail}${at}${client}`}
            </a>
        </div>
    </>
);

export default Card;